jQuery(function($){

  //IEのobject-fit対応
  objectFitImages();

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}
